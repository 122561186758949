// $(document).ready(function(){
//
//   // Write your Javascript!
//   $('form.bbg-form-contact').on('submit', function(event) {
//     var $form = $(this);
//     $form.find(".form-group").removeClass('has-error');
//     $.post(
//       $form.attr('action'),
//       $form.serialize(),
//       function(data, textStatus, xhr) {
//         if (data.success == false) {
//           $.each(data.errors, function(index, val) {
//             $('[name="'+index+'"]')
//               .parent('.form-group')
//               .addClass('has-error');
//           });
//         } else if (data.success) {
//           $form.next('.hidden').removeClass('hidden');
//           $form.remove();
//         }
//       }
//     );
//
//     event.preventDefault();
//     return false;
//   });
//
// });